<template>
    <div>

        <div v-if="showAddExamModal">
            <AddQestionExamModal @add-question="addQuestion" @add-existing-question="addExistingQuestion" :closeAddQuestionModal="closeAddQuestionModal" :addTab="addTab" :selectedModuleId="selectedModuleId" :tabIndex="tabIndex"/>
        </div>  

        <div class="flex sm:flex-row flex-col justify-between items-center mb-10 ">
            <div class="sm:w-1/2 w-full">
                <div class="mb-2">
                    <p class="font-semibold text-sm text-gray-800 text-left ml-2">Nom examen</p>
                </div>
                <el-input type="text" v-model="examtitle"  placeholder="Insérez le nom de l'examen" />
            </div>
            <div class="flex flex-col sm:w-1/3 sm:mx-2 w-full">
              <div class="mb-2">
                <p class="font-semibold text-sm text-gray-800 text-left ml-2">Année</p>
              </div>
              <el-date-picker size="meduim" class="w-full" v-model="examDate" type="month" value-format="x" placeholder="Sélectionnez une année"></el-date-picker>
            </div>
            <div class="flex flex-col sm:my-0 my-2 justify-center items-center sm:mx-4 mx-2">
                <div>Activer</div>
                <el-switch class="mt-2" v-model="showExam"> </el-switch>
            </div>
            <div v-if="tabIndex == 1 || tabIndex == 0" class="text-center sm:my-0 my-2">
                {{tabIndex}} Question
            </div>
            <div v-else class="text-center sm:my-0 my-2">
                {{tabIndex}} Questions
            </div>
        </div>
        <el-divider>
            <button @click="showAddExamModal=true" class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                Ajouter une question
            </button>
        </el-divider>
        <div class=" w-full flex flex-col justify-center items-center">
            <el-scrollbar class="max-w-xs sm:max-w-xl w-full py-4">
                <draggable class=" mt-6  w-full  flex flex-row" :list="editableTabs" @change="log">
                    <button
                        class=" list-group-item mx-1 py-1 px-2 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg "
                        v-for="element in editableTabs"
                        :key="element.name"
                    >
                        {{ element.name }} 
                    </button>
                </draggable>
            </el-scrollbar>
            <el-tabs
              class="w-full mt-10  max-w-xs sm:max-w-xl"
              v-model="editableTabsValue"
              type="card"
              closable
              @tab-remove="removeTab"
            >
          
                <el-tab-pane
                    v-for="item in editableTabs"
                    :key="item.name"
                    :label="item.title"
                    :name="item.name"
                > 

                    <div class="border rounded-lg mb-5">
                        <div v-if="item.questions.type != 'clinicalcas'" class="border-b px-3 py-2">
                            Question 
                        </div>
                        <div v-else  class="border-b px-3 py-2">
                            Cas clinique
                        </div>
                        <div class="p-3 br">
                            {{item.questions.question}}
                        </div>
                    </div>

                    <div v-if="item.questions.type== 'qroc'" class="border rounded-lg">
                        <div class="border-b px-3 py-2">
                            Réponse type 
                        </div>
                        <div class="p-3">
                        {{item.questions.reponsetype}}
                        </div>
                    </div>

                    <div v-if="item.questions.type== 'qcm' || item.questions.type== 'qcs'" class="border rounded-lg">
                        <div class="px-3 py-2">
                            Suppositions 
                        </div>
                        <div>
                            <div class="flex justify-between items-center border-t p-2" v-for="proposal in item.questions.proposals" :key="proposal.id">
                                <div>
                                    {{proposal.proposal}}
                                </div>
                                <div v-if="proposal.state == '1' " class="h-3 w-3 bg-green-400 rounded-full"></div>
                                <div v-else class="h-3 w-3 bg-red-400 rounded-full"></div>
                            </div>
                        </div>
                    </div>


                    <div v-if="item.questions.type== 'clinicalcas'" class="">
                        <el-collapse  v-model="activeNames" @change="handleChange">
                            <el-collapse-item class="px-4"  v-for="(cas,index) in item.questions.clinicalCase" :key="cas.clinical_case_id" :title="'Question ' + (index+1)"  >
                                <div>

                                    <div class="border rounded-lg  mb-3">
                                        <div class="border-b px-2 py-1">
                                            Question 
                                        </div>
                                        <div class="p-2">
                                            {{ cas.question }}
                                        </div>
                                    </div>

                                    <div v-if="cas.type== 'qcm' || cas.type== 'qcs'" class="border rounded-lg">
                                        <div class="px-2 py-1">
                                            Suppositions 
                                        </div>
                                        <div>
                                            <div class="flex justify-between items-center border-t py-1 px-2" v-for="proposal in cas.proposals" :key="proposal.id">
                                                <div>
                                                    {{proposal.proposal}}
                                                </div>
                                                <div v-if="proposal.state == '1' " class="h-2 w-2 bg-green-400 rounded-full"></div>
                                                <div v-else class="h-2 w-2 bg-red-400 rounded-full"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="cas.type== 'qroc'" class="border rounded-lg">
                                        <div class="border-b px-2 py-1">
                                            Réponse type 
                                        </div>
                                        <div class="p-2">
                                            {{cas.response}}
                                        </div>
                                    </div>

                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                      
                </el-tab-pane>
            </el-tabs>
        </div>

        <div class="w-full flex justify-center  mt-6">
            <button v-if="(editableTabs.length > 1) && (examtitle)" @click="ConfirmExam" class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
              Ajouter un examen
            </button>
        </div>


    </div>
</template>

<script>

import AddQestionExamModal from '../dashboard/AddQuestionExamModal.vue';
import { ElMessage } from 'element-plus';
import { VueDraggableNext } from 'vue-draggable-next';
import axiosIns from '../../plugins/axios';

export default {
    components: {
        AddQestionExamModal,
        draggable: VueDraggableNext,
    },
    data () {
        return {
            examtitle : null,
            examDate: null,
            showExam: true,

            editableTabsValue: '1',
            editableTabs: [],
            tabIndex: 0,

            showAddExamModal: false,
            questions:[],
            ExistingQuestions:[],
        }
    },

    props: {
        selectedModuleId : Number,
        resetAddExam:Function
    },  

    methods: {
        closeAddQuestionModal(){
            this.showAddExamModal = false;
        },
        log(event) {
            let min = Math.min(event.moved.newIndex , event.moved.oldIndex)
            let max = Math.max(event.moved.newIndex , event.moved.oldIndex)

            this.editableTabs[min].questions.position = min + 1

            for (let i = min + 1 ; i < max ; i++) {
                this.editableTabs[i].questions.position = i + 1;
            }

            this.editableTabs[max].questions.position = max + 1;

            this.questions = [];
            this.ExistingQuestions = [];
            this.editableTabs.forEach(element => {
                if(element.idQuestion){
                    this.ExistingQuestions = [...this.ExistingQuestions,element.questions];
                }else{
                    this.questions = [...this.questions, element.questions];
                }   
            });
        },

        addTab(question) {
            let newTabName = ++this.tabIndex + ''
            if(question.id){
                this.editableTabs.push({
                    title: 'Question '+newTabName,
                    name: newTabName,
                    idQuestion: question.id,
                    questions: question,
                })
            }

            if(!question.id){
                this.editableTabs.push({
                    title: 'Question '+newTabName,
                    name: newTabName,
                    idQuestion: null,
                    questions: question,
                })
                this.editableTabsValue = newTabName
            }

            this.editableTabsValue = newTabName
        },


        removeTab(targetName) {
            this.tabIndex--;
            let tabs = this.editableTabs;
            let activeName = this.editableTabsValue;
            let check;

            if (activeName === targetName) {
                tabs.forEach((tab, index) => {
                    if (tab.name === targetName) {
                        let nextTab = tabs[index + 1] || tabs[index - 1]
                        if (nextTab) {
                            activeName = nextTab.name
                        } 
                        check = tab.idQuestion;              
                    }
                })
            }
            this.editableTabsValue = activeName ; 
            this.editableTabs = tabs.filter((tab) => tab.name !== targetName) ;
            if(check !== null){
                this.ExistingQuestions = this.ExistingQuestions.filter((tab) => tab.name !== targetName);
            }
            else{       
               this.questions = this.questions.filter((tab) => tab.name !== targetName);
            }

            let j = 0;
            let t = 0;
            for (let i = 0 ; i < this.editableTabs.length ; i++) {
                this.editableTabs[i].name = (i + 1).toString();
                this.editableTabs[i].title = 'Question '+this.editableTabs[i].name ;
                this.editableTabs[i].questions.position = i + 1;
                if(this.editableTabs[i].idQuestion !== null){
                    this.ExistingQuestions[j].name = (i + 1).toString();
                    this.ExistingQuestions[j].position = i + 1;
                    j++;
                }
                else{
                    this.questions[t].name = (i + 1).toString();
                    this.questions[t].position = i + 1;
                    t++;
                }

            }
  
        },

        addQuestion(question){
            question.name = this.tabIndex+1 + '';
            this.questions = [...this.questions, question];
            this.addTab(question);
        },

        addExistingQuestion(question){
            question.name = this.tabIndex+1 + '';
            this.ExistingQuestions = [...this.ExistingQuestions,question];
            this.addTab(question);
        },


        ConfirmExam(){
            let tmpShow ;
            if (this.showExam === true) {
                tmpShow = "1";
            }
            else {
                tmpShow = "0";
            }
            
            axiosIns.post("/examen",
                {
                    module_id: this.selectedModuleId,
                    name: this.examtitle,
                    year: this.examDate,
                    show: tmpShow,
                    questions: this.questions,
                    exists: this.ExistingQuestions,
                }
            ).then(() => {
                this.resetExam(); 
                this.resetAddExam();
                this.$emit("add-succes");
            }).catch(() => {
                this.errorm();
            });
        },
        
        
        resetExam(){
            this.examtitle = null;
            this.examDate = null;
            this.showExam = true;
            this.questions = [];
            this.ExistingQuestions = [];
            this.editableTabs = [];
        },
    },

    computed: {
        isToken() {
            return this.$store.getters.get_token;
        }
    },

    setup(){
     const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }

    return{errorm}
    }


}
</script>

<style>

</style>